.ce-block__content,
.ce-toolbar__content {
	max-width: unset;
}

.simple-image {
	padding: 20px 0;
}

.simple-image input,
.simple-image [contenteditable] {
	width: 100%;
	padding: 10px;
	border: 1px solid #e4e4e4;
	border-radius: 3px;
	outline: none;
	font-size: 14px;
}

.simple-image img {
	max-width: 100%;
	margin-bottom: 15px;
}

.simple-image.withBorder img {
	border: 1px solid #e8e8eb;
}

.simple-image.withBackground {
	background: #eff2f5;
	padding: 10px;
}

.simple-image.withBackground img {
	display: block;
	max-width: 60%;
	margin: 0 auto 15px;
}

.embed-tool__content {
	border-bottom: 5px solid red;
	cursor: nwse-resize;
}

.ce-code__textarea {
	min-height: 200px;
	font-family: Menlo, Monaco, Consolas, Courier New, monospace;
	color: #fff;
	line-height: 1.6em;
	font-size: 12px;
	background: #1f2937;
	border: 1px solid #f1f1f4;
	box-shadow: none;
	white-space: pre;
	word-wrap: normal;
	overflow-x: auto;
	resize: vertical;
}
