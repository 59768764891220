.sudoku-game {
	display: flex;
	flex-wrap: wrap;
	background: rgb(230, 230, 230);
	margin: 0 auto;
}

.sudoku-segment {
	border: 1px solid gray;
	margin: 1px;
	padding: 1px;
	width: calc(100% / 3 - 6px);
	height: calc(100% / 3 - 6px);
	display: flex;
	flex-wrap: wrap;
}

.sudoku-cell {
	text-align: center;
	font-size: 150%;
	width: calc(100% / 3 - 2px);
	height: calc(100% / 3 - 2px);
	border: none;
	// outline: none;
	padding: 0;
	margin: 1px;
	color: #0072e3;
	background: white;

	&.started-cell {
		color: #6e7c96;

		&.error {
			color: #ed0a0a;
		}
	}

	&.supported-cell {
		background: #eaeef4;
	}

	&.selected-cell {
		background: #c2deff;
	}

	&.important-cell {
		color: blue;
	}

	&.error-cell {
		background-color: #eed9d9;
		color: red;
	}
}

.sudoku-controls {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	align-content: space-between;
	width: 100%;

	.number-selector {
		margin: 5px;
		padding: 5px;
		flex: 0 1 auto;
		color: #0072e3;
		border-radius: 5px;
		border: 1px solid #0072e3;
	}

	.sudoku-level {
		font-size: 20px;
		color: #0072e3;
		background: white;
	}

	.sudoku-clear-button {
		background: white;
		color: #0072e3;
		border-radius: 5px;
		border: 1px solid #0072e3;
	}
}

.dark {
	.sudoku-game {
		background: #11264c;
		.sudoku-cell {
			background: #111927;

			&.started-cell {
				color: #6e7c96;

				&.error {
					color: #ed0a0a;
				}
			}

			&.supported-cell {
				background: #c2deff;
			}

			&.selected-cell {
				background: #99bce4;
			}

			&.important-cell {
				color: blue;
			}

			&.error-cell {
				background-color: #eed9d9;
				color: red;
			}
		}
		.sudoku-clear-button,
		.sudoku-level {
			background: #111927;
			color: #6e7c96;
		}
	}
}
