@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('sudoku.css'); */

.remark-code-title {
	@apply px-5 py-3 font-mono text-sm font-bold text-gray-200 bg-gray-700 rounded-t;
}

.remark-code-title + pre {
	@apply mt-0 rounded-t-none;
}

.task-list-item:before {
	@apply hidden;
}

html {
	scroll-behavior: smooth;
}

.section-headline {
	@apply font-bold sm:text-xl uppercase tracking-widest border-green-500 border-l-8 pl-2;
}

progress {
	margin-top: 0.5rem;
	width: 100%;
	height: 10px;
	margin-bottom: 0.4rem;
}

progress::-webkit-progress-bar {
	border-radius: 10px;
	background-color: #d1d5db;
	box-shadow: 0 2px 6px #555;
}

progress::-webkit-progress-value {
	border-radius: 10px 0 0 10px;
	background-image: linear-gradient(36deg, #d1fae5, #818cf8);
}

.section-headline {
	color: #10b981 !important;
}

#chrisko-headline::before {
	display: inline-block;
	height: 30px;
	width: 30px;
	position: relative;
	bottom: -8px;
	content: '';
	/* background-image: url('./assets/chrisko-icon.png'); */
	background-position: center;
	background-size: contain;
	margin-right: 12px;
	border-radius: 50%;
}

canvas {
	/* stack canvas' */
	position: absolute;
}
